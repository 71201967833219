<template>
  <div class="order-pay-modal" v-show="open">
    <div class="modal-box animated fadeInDownBig">
      <div class="top-box">
        <div class="close-icon" @click="close"></div>
        <div class="pay-container">
          <div
            class="pay-box"
            :class="payType == 1 ? 'pay-select' : ''"
            @click="handleChangePay(1)"
          >
            移动支付
          </div>
          <div
            class="pay-box"
            :class="payType == 2 ? 'pay-select' : ''"
            @click="handleChangePay(2)"
          >
            对公支付
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div v-if="showFlag" style="width: 100%; height: 100%; margin-top: 100px">
          <empty name="暂无数据"></empty>
        </div>
      </div>
      <div class="bottom-box" v-if="!showFlag">
        <div class="pay-info">
          <div class="content-box">
            <div class="box-title">服务内容</div>
            <div class="box-content text-ellipsis" :title="detailData.commodityName">
              {{ detailData.commodityName }}
            </div>
          </div>
          <div class="content-box" style="margin-top: 4px">
            <div class="box-title">支付金额</div>
            <div class="pay-price">
              <span>￥</span>
              <span class="price">{{ detailData.orderAmount }}</span>
            </div>
          </div>
        </div>
        <div class="pay-type">
          <div class="pay-mobile" v-show="payType == 1">
            <div class="pay-mobile-type">
              <div>支付方式</div>
              <div
                class="type-wechat"
                :class="mobilePayType == 1 ? 'mobile-select' : ''"
                @click="handleChangeMobilePay(1)"
              >
                <div class="type-icon"></div>
                <span>微信支付</span>
                <div class="type-select" v-show="mobilePayType == 1"></div>
              </div>
              <div
                class="type-alipay"
                :class="mobilePayType == 2 ? 'mobile-select' : ''"
                @click="handleChangeMobilePay(2)"
              >
                <div class="type-icon"></div>
                <span>支付宝支付</span>
                <div class="type-select" v-show="mobilePayType == 2"></div>
              </div>
            </div>
            <div class="agree-box">
              <el-checkbox v-model="agreeChecked"> </el-checkbox>
              <span class="content">我已阅读并同意</span>
              <span class="agree" @click="openAgree">
                《{{ mobilePayType == 1 ? "微信" : "支付宝" }}支付须知》
              </span>
            </div>
            <div class="pay-msg" v-show="agreeChecked">
              <span>扫码购买</span>
              <span>打开微信/支付宝扫描二维码支付</span>
            </div>
            <div class="code-img" v-show="agreeChecked">
              <img @dragstart.prevent src="../../assets/img/code-one.png" alt="" />
              <div class="code-mark" v-show="false">
                <span class="iconfont icon-jiazai_shuaxin"></span>
                <span>刷新二维码</span>
              </div>
            </div>
          </div>
          <div class="pay-contrary" v-show="payType == 2">
            <div class="msg-box" v-for="(item, i) in contraryData" :key="i">
              <div class="msg-name">{{ item.name }}</div>
              <div class="msg-item">{{ item.conunt }}</div>
            </div>
            <div class="msg-box">
              <div class="msg-name">上传支付回执</div>
              <el-upload
                action="*"
                :http-request="uploadReceipt"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload"
                accept="image/*"
                :file-list="fileList"
              >
                <div class="upload-butoon" v-show="fileList.length == 0">
                  <span class="iconfont icon-shangchuanwenjian"></span>
                  <span>点击上传</span>
                </div>
              </el-upload>
            </div>
            <div class="agree-box">
              <el-checkbox v-model="contraryChecked"> </el-checkbox>
              <span class="content">我已阅读并同意</span>
              <span class="agree" @click="openAgree"> 《对公支付须知》 </span>
            </div>
            <el-button type="primary" @click="submitConfirm">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <agreement-modal
      ref="agreementModal"
      :title="mobilePayType == 1 ? '微信' : '支付宝' + '支付须知'"
    ></agreement-modal>
    <agreement-modal ref="publicModal" :title="'对公支付须知'"></agreement-modal>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
import AgreementModal from "@/components/agreementModal";
export default {
  components: {
    Empty,
    AgreementModal,
  },
  data() {
    return {
      open: false,
      type: null, // 1 （阅读支付）2 （下载支付）
      payType: 1, // 1 （移动支付）2 （对公支付）
      mobilePayType: 1, // 1 （微信支付）2 （支付宝支付）
      agreeChecked: false, // 移动支付须知
      contraryChecked: false, // 对公支付须知
      detailData: {},
      showFlag: false,
      contraryData: [
        {
          name: "企业名称",
          conunt: "-",
        },
        {
          name: "银行名称",
          conunt: "-",
        },
        {
          name: "收款银行账号",
          conunt: "-",
        },
        {
          name: "银行网点名称",
          conunt: "-",
        },
        {
          name: "专属客服电话",
          conunt: "-",
        },
      ],
      fileList: [], // 上传的回执
      paymentReceipt: "", // 回执id
    };
  },
  watch: {
    // 监听弹窗控制滚动条显示与隐藏
    // open(newVal) {
    //   if (newVal) {
    //     window.document.body.style.overflowY = "hidden";
    //   } else {
    //     window.document.body.style.overflowY = "scroll";
    //   }
    // },
  },
  mounted() {},
  methods: {
    // 初始化数据
    init(type, data) {
      // type == 1 （阅读支付）type == 2 （下载支付）
      this.type = type;
      this.detailData = data;
      // 获取财务对公账户详细信息
      // this.$api.personalCenter
      //   .payReceiptInfo()
      //   .then(res => {
      //     if (res.data) {
      //       let data = res.data;
      //       this.contraryData[0].conunt = data.corpname;
      //       this.contraryData[1].conunt = data.bankName;
      //       this.contraryData[2].conunt = data.bankAccount;
      //       this.contraryData[3].conunt = data.backAddress;
      //       this.contraryData[4].conunt = data.customerServiceTelephone;
      //     } else {
      //       this.contraryData = [];
      //       this.showFlag = true;
      //     }
      //   })
      //   .catch(msg => {
      //     if (msg?.msg) {
      //   this.$message.error(msg?.msg);
      // }
      //   });
    },
    // 关闭弹窗
    close() {
      this.open = false;
      this.fileList = [];
      this.payType = 1;
      this.mobilePayType = 1;
      this.agreeChecked = false;
      this.contraryChecked = false;
    },
    // 点击移动支付或对公支付
    handleChangePay(type) {
      this.payType = type;
      if (type == 2) {
        this.$api.personalCenter
          .payReceiptInfo()
          .then((res) => {
            if (res.data) {
              let data = res.data;
              this.contraryData[0].conunt = data.corpname;
              this.contraryData[1].conunt = data.bankName;
              this.contraryData[2].conunt = data.bankAccount;
              this.contraryData[3].conunt = data.backAddress;
              this.contraryData[4].conunt = data.customerServiceTelephone;
              this.showFlag = false;
            } else {
              this.contraryData = [];
              this.showFlag = true;
            }
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      } else {
        this.showFlag = false;
      }
    },
    // 点击微信支付或支付宝支付
    handleChangeMobilePay(type) {
      this.mobilePayType = type;
    },
    // 上传前图片判断
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    // 上传图片
    uploadReceipt(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false,
      });
      this.$api.personalCenter
        .upload(form)
        .then((res) => {
          this.$message.success("上传成功");
          file.filePath = res.data.fileName;
          file.url = res.data.url;
          this.fileList.push(file);
          this.paymentReceipt = res.data.fileId;
        })
        .catch((err) => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    // 移除上传的回执
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    // 对公支付确认
    submitConfirm() {
      let { type, detailData, paymentReceipt, payType, mobilePayType } = this;
      if (this.fileList.length == 0) {
        this.$message.error("请上传支付回执");
        return;
      }
      if (!this.contraryChecked) {
        this.$message.error("请勾选支付须知");
        return;
      }
      let paymentWay = "";
      if (payType == 1) {
        if (mobilePayType == 1) {
          paymentWay = "60491001";
        } else {
          paymentWay = "60491002";
        }
      } else {
        paymentWay = "60591003";
      }
      let params = {
        paymentReceipt,
        paymentWay,
        orderCode: detailData.orderCode,
      };
      if (type == 1) {
        this.$api.personalCenter
          .payReport(params)
          .then(() => {
            this.$message.success("提交成功，等待管理员审核");
            this.close();
            setTimeout(() => {
              this.$parent.reportPage();
            }, 200);
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      } else if (type == 2) {
        this.$api.personalCenter
          .payConsult(params)
          .then(() => {
            this.$message.success("提交成功，等待管理员审核");
            this.close();
            setTimeout(() => {
              this.$parent.consultPage();
            }, 200);
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      } else if (type == 3) {
        this.$api.personalCenter
          .payMember(params)
          .then(() => {
            this.$message.success("提交成功，等待管理员审核");
            this.close();
            setTimeout(() => {
              this.$parent.memberPage();
            }, 200);
          })
          .catch((msg) => {
            if (msg?.msg) {
              this.$message.error(msg?.msg);
            }
          });
      }
    },
    // 点击协议
    openAgree() {
      if (this.payType == "move") {
        this.$refs.agreementModal.visible = true;
        // this.$refs.agreementModal.init();
      } else {
        this.$refs.publicModal.visible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
